import { HttpClient } from '../common';
import objectHash from 'object-hash';

export type TaskResponse<T> = {
  result: T;
};

export type TaskConfig = {
  name: string;
  params?:
    | (Record<string, unknown> & {
        files?: (Blob | File)[];
      })
    | null;
  cache?: {
    invalid?: string[];
    save?: string[];
  };
};

export class EBTask {
  constructor(private httpClient: HttpClient) {}

  async executeWithConfig<T = unknown>(taskConfig: TaskConfig): Promise<TaskResponse<T>> {
    const { name, params, cache } = taskConfig;
    const { files = [], ...parameters } = params ?? {};

    return this.execute(name, { files, parameters }, cache);
  }

  async execute<T = unknown>(
    name: string,
    params: Record<string | number, unknown> | Record<string | number, unknown>[] = {},
    cache: { invalid?: string[]; save?: string[] } = {}
  ): Promise<TaskResponse<T>> {
    const url = `/v1/task/execute/${name}`;
    let enableCache = !!cache.save?.length;

    const formData = new FormData();

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((child) => {
          if (child instanceof Blob || child instanceof File) {
            // disable cache when there is a File/Blob in inputs
            enableCache = false;

            formData.append(key, child);
            return;
          }

          formData.append(key, typeof child !== 'string' ? JSON.stringify(child) : child);
        });

        return;
      }

      if (value instanceof Blob || value instanceof File) {
        // disable cache when there is a File/Blob in inputs
        enableCache = false;

        formData.append(key, value);
        return;
      }

      formData.append(key, typeof value !== 'string' ? JSON.stringify(value) : value);
    });

    if (enableCache) {
      const cached = window.EB.cache.get<TaskResponse<T>>({
        url,
        params
      });

      if (cache.invalid?.length) {
        cache.invalid?.forEach((tag) => {
          window.EB.cache.invalidByTag(tag);
        });
      }

      if (cached) {
        return Promise.resolve(cached);
      }
    }

    const response = await this.httpClient.post<TaskResponse<T>>(url, formData);

    if (enableCache) {
      const cacheTagForNameAndParams = objectHash({ url, params });

      window.EB.cache.invalidByTag(cacheTagForNameAndParams);

      const cacheTags = [...(cache.save ?? [])];

      cacheTags.push(cacheTagForNameAndParams);

      window.EB.cache.save({ url, params }, response, cacheTags);
    }

    if (cache.invalid?.length) {
      cache.invalid?.forEach((tag) => {
        window.EB.cache.invalidByTag(tag);
      });
    }

    return response;
  }

  downloadByKey(key: string): void {
    const url = `/v1/task/download/key/${key}`;

    this.httpClient.open(url);
  }

  async devReload(): Promise<boolean> {
    const url = '/v1/task/dev/reload';

    const response = await this.httpClient.post<{ success: boolean }>(url, {});

    return response.success;
  }

  getDownloadByKeyUrl(key: string): string {
    const url = `/v1/task/download/key/${key}`;

    return `${this.httpClient.baseUrl}${url}`;
  }
}
