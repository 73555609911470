import debounce from 'lodash-es/debounce';
import mitt, { Emitter } from 'mitt';
import { AuthorizeAction, AuthorizeResult, LoginCredentials } from '../auth';
import { EBAPIError } from '../common';
import { EBDatabase, ExcelQueryConfig, QueryConfig } from '../database';
import { EBIntl } from '../languages';
import { EBTask } from '../task';
import { clone } from '../utils/clone';

export enum CUSTOM_PARAMS {
  IN_CHAIN = '__in_chain',
  WITHOUT_SPIN = '__without_spin',
  WITHOUT_MASK = '__without_mask'
}

export enum COMMON_EVENTS {
  ALERT = 'show-alert',
  LOG_INFO = 'log-info',
  EB_DOC_TITLE = 'eb-doc-title',
  EB_QUERY = 'eb-query',
  EB_QUERY_EXCEL = 'eb-query-excel',
  EB_AUTHORIZE = 'eb-authorize',
  EB_MUTATION = 'eb-mutation',
  EB_CONFIRM = 'eb-confirm',
  EB_TASK = 'eb-task',
  EB_ALERT = 'eb-alert',
  EB_TOAST = 'eb-toast',
  EB_SET_INTERVAL = 'eb-set-interval',
  EB_CLEAR_INTERVAL = 'eb-clear-interval',
  EB_MANUAL = 'eb-manual',
  EB_NEW_BROWSER_TAB = 'eb-new-browser-tab',
  EB_OPEN_URL = 'eb-open-url',
  EB_SUBMIT_LOGIN = 'eb-submit-login',
  EB_ROUTE_REDIRECT = 'eb-route-redirect',
  EB_ROUTER_PUSH = 'eb-router-push',
  EB_ROUTER_REPLACE = 'eb-router-replace',
  EB_RELOAD_DEV_APP = 'eb-reload-dev-app',
  EB_CACHE_SAVE = 'eb-cache-save',
  EB_CACHE_INVALID = 'eb-cache-invalid',
  EB_STATE_STORE_REMOVE = 'eb-state-store-remove',
  EB_POST_MESSAGE = 'eb-post-message',
  EB_MESSAGE_RECEIVED = 'eb-message-received',
  EB_SIGNAL_SUBSCRIBE = 'eb-signal-subscribe',
  EB_SIGNAL_UNSUBSCRIBE = 'eb-signal-unsubscribe',
  EB_SIGNAL_RECEIVED = 'eb-signal-received',
  EB_CHAIN_DONE = 'eb-done',
  EB_CHAIN_ERROR = 'eb-error'
}

export type EmitChain = {
  name: string;
  key: string;
  params?: ($emit: Record<string, unknown>) => Record<string, unknown>;
  depends?: string[];
  checks?: ($emit: Record<string, unknown>) => boolean[];
  handler?: ($emit: Record<string, unknown>, error?: unknown) => unknown;
  children?: EmitChain[];
  authorize?: AuthorizeAction;
  delay?: number;
};

export class EBEmitter {
  emitter: Emitter;
  ebIntervalIds: Record<string, unknown> = {};

  numOfExecutionLoading = 0;
  numOfExecutionMasking = 0;
  loadingBlocker: HTMLDivElement;
  closeLoadingSpinner?: () => void;
  logOnOffEvents = false;

  showAsyncExecutionMessage: (loadingMessage: string) => () => void = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (): void => {};
  };

  refreshExecutionLoadingMasking = debounce(() => {
    if (this.numOfExecutionLoading > 0) {
      if (!this.closeLoadingSpinner) {
        this.closeLoadingSpinner = this.showAsyncExecutionMessage(
          this.intl.formatMessage('__eb_executing_message')
        );
      }
    } else {
      this.closeLoadingSpinner?.();
      this.closeLoadingSpinner = undefined;
    }

    if (this.numOfExecutionMasking > 0) {
      if (!document.body.contains(this.loadingBlocker)) {
        document.body.appendChild(this.loadingBlocker);
      }
    } else {
      if (document.body.contains(this.loadingBlocker)) {
        document.body.removeChild(this.loadingBlocker);
      }
    }
  }, 100);

  showAsyncExecutionMessageInternal = (withoutMask?: boolean) => {
    this.numOfExecutionLoading++;

    if (!withoutMask) {
      this.numOfExecutionMasking++;
    }

    this.refreshExecutionLoadingMasking();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {
      this.numOfExecutionLoading--;

      if (!withoutMask) {
        this.numOfExecutionMasking--;
      }

      this.refreshExecutionLoadingMasking();
    };
  };

  routerPush: (options: {
    name?: string;
    path?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: Record<string, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query?: Record<string, any>;
  }) => Promise<unknown> = (options) => {
    window.location.href = options.path ?? '';

    return Promise.resolve();
  };

  routerReplace: (options: {
    name?: string;
    path?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: Record<string, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query?: Record<string, any>;
  }) => Promise<unknown> = (options) => {
    window.location.href = options.path ?? '';

    return Promise.resolve();
  };

  confirm: (message: string) => Promise<boolean> = (message) => {
    return new Promise<boolean>((resolve) => {
      return resolve(window.confirm(message));
    });
  };

  alert: (message: string, type: 'success' | 'error' | 'info' | 'warning') => Promise<void> = (
    message
  ) => {
    return new Promise<void>((resolve) => {
      window.alert(message);

      return resolve();
    });
  };

  toast: (message: string, type: 'success' | 'error' | 'info' | 'warning') => Promise<void> = (
    message,
    type
  ) => {
    return this.alert(message, type);
  };

  constructor(
    private logEnabled: boolean,
    private db: EBDatabase,
    private task: EBTask,
    private intl: EBIntl
  ) {
    this.emitter = mitt();

    this.logOnOffEvents =
      logEnabled && localStorage.getItem('__eb_emitter_log_on_off_enabled') === 'true';
    this.loadingBlocker = document.createElement('div');
    this.loadingBlocker.id = 'eb-loading-block';
    this.loadingBlocker.style.position = 'fixed';
    this.loadingBlocker.style.top = '0';
    this.loadingBlocker.style.left = '0';
    this.loadingBlocker.style.right = '0';
    this.loadingBlocker.style.bottom = '0';
    this.loadingBlocker.style.zIndex = '1001';
    this.loadingBlocker.style.backgroundColor = 'transparent';

    this.emitter.on(COMMON_EVENTS.EB_QUERY, (params?: QueryConfig) => {
      if (!params?.name) {
        throw new Error('EBEmitter - eb-query - Missing name of query');
      }

      return Promise.resolve(this.db.queryWithConfig(params));
    });

    this.emitter.on(COMMON_EVENTS.EB_QUERY_EXCEL, async (params?: ExcelQueryConfig) => {
      if (!params?.name) {
        throw new Error('EBEmitter - eb-query - Missing name of query');
      }

      const results = await this.db.queryToExcelWithConfig(params);

      if (!results.downloadKey) {
        this.alert(this.intl.formatMessage('__eb_excel_export_empty_message'), 'error');

        return;
      }

      const { downloadKey, downloadName } = results;

      // Need to replace `/`, `\`, `+` to prevent unexpected 404 on url
      window.open(
        window.EB.task.getDownloadByKeyUrl(downloadKey) +
          (downloadName ? '/' + encodeURIComponent(downloadName.replace(/[/\\+]/g, '_')) : '')
      );

      return;
    });

    this.emitter.on(
      COMMON_EVENTS.EB_MUTATION,
      (params?: {
        name?: string;
        params: Record<string, unknown>;
        cache?: { invalid?: string[] };
        [CUSTOM_PARAMS.IN_CHAIN]: boolean;
        [CUSTOM_PARAMS.WITHOUT_SPIN]?: boolean;
        [CUSTOM_PARAMS.WITHOUT_MASK]?: boolean;
      }) => {
        const closeAsyncMessage =
          params?.[CUSTOM_PARAMS.IN_CHAIN] || params?.[CUSTOM_PARAMS.WITHOUT_SPIN]
            ? null
            : this.showAsyncExecutionMessageInternal(params?.[CUSTOM_PARAMS.WITHOUT_MASK]);

        if (!params?.name) {
          throw new Error('EBEmitter - eb-mutation - Missing name of mutation');
        }

        return Promise.resolve(
          this.db.mutation(params.name, params?.params, params?.cache)
        ).finally(() => {
          closeAsyncMessage?.();
        });
      }
    );

    this.emitter.on(COMMON_EVENTS.EB_CONFIRM, (params: unknown) => {
      const castParams = params as
        | { message: string; values?: Record<string, unknown> }
        | undefined;
      const { message, values } = castParams ?? {};

      if (!message) {
        throw new Error('EBEmitter - eb-confirm - Missing message');
      }

      const translatedMessage = this.intl.formatMessage(message, values);

      return Promise.resolve(this.confirm(translatedMessage));
    });

    this.emitter.on(COMMON_EVENTS.EB_NEW_BROWSER_TAB, (params: unknown) => {
      const castParams = params as { url: string; downloadName?: string } | undefined;
      const { url, downloadName } = castParams ?? {};

      if (!url) {
        throw new Error('EBEmitter - eb-new-browser-tab - Missing url in params');
      }

      // Need to replace `/`, `\`, `+` to prevent unexpected 404 on url
      window.open(
        url + (downloadName ? '/' + encodeURIComponent(downloadName.replace(/[/\\+]/g, '_')) : '')
      );

      return Promise.resolve();
    });

    this.emitter.on(COMMON_EVENTS.EB_OPEN_URL, (params: unknown) => {
      const castParams = params as { url: string; newTab?: boolean } | undefined;
      const { url, newTab } = castParams ?? {};

      if (!url) {
        throw new Error('EBEmitter - eb-open-url - Missing url in params');
      }

      if (newTab) {
        window.open(url);
      } else {
        window.location.href = url;
      }

      return Promise.resolve();
    });

    this.emitter.on(COMMON_EVENTS.EB_ALERT, (params: unknown) => {
      const castParams = params as
        | { message: string; values?: Record<string, unknown>; type?: 'success' | 'error' }
        | undefined;
      const { message, values, type = 'success' } = castParams ?? {};

      if (!message) {
        throw new Error('EBEmitter - eb-alert - Missing message');
      }

      const translatedMessage = this.intl.formatMessage(message, values);

      return Promise.resolve(this.alert(translatedMessage, type));
    });

    this.emitter.on(COMMON_EVENTS.EB_RELOAD_DEV_APP, async () => {
      const success = await this.task.devReload();

      if (success) {
        window.location.reload();
      } else {
        this.alert("Couldn't reload dev app", 'error');
      }

      return Promise.resolve(true);
    });

    this.emitter.on(COMMON_EVENTS.EB_TOAST, (params: unknown) => {
      const castParams = params as
        | { message: string; values?: Record<string, unknown>; type?: 'success' | 'error' }
        | undefined;
      const { message, values, type = 'success' } = castParams ?? {};

      if (!message) {
        throw new Error('EBEmitter - eb-toast - Missing message');
      }

      const translatedMessage = this.intl.formatMessage(message, values);

      return Promise.resolve(this.toast(translatedMessage, type));
    });

    this.emitter.on(
      COMMON_EVENTS.EB_TASK,
      async (params?: {
        name: string;
        params: Record<string, unknown>;
        cache?: { invalid?: string[]; save?: string[] };
        [CUSTOM_PARAMS.IN_CHAIN]?: boolean;
        [CUSTOM_PARAMS.WITHOUT_SPIN]?: boolean;
        [CUSTOM_PARAMS.WITHOUT_MASK]?: boolean;
      }) => {
        if (!params?.name) {
          throw new Error('EBEmitter - eb-task - Missing name of task');
        }

        const isInChain = !!params[CUSTOM_PARAMS.IN_CHAIN];
        const closeAsyncMessage =
          isInChain || params[CUSTOM_PARAMS.WITHOUT_SPIN]
            ? null
            : this.showAsyncExecutionMessageInternal(params[CUSTOM_PARAMS.WITHOUT_MASK]);

        let onDone: ((result: unknown) => void) | null = null;

        const { name, params: taskParams, cache } = params;

        if (taskParams?.onDone) {
          onDone = taskParams.onDone as (result: unknown) => void;
        }

        const { files = [], ...parameters } = taskParams ?? {};

        delete parameters.onDone;

        const customizeParamsForTask = { files, parameters };

        try {
          const result = await Promise.resolve(
            this.task.execute(name, customizeParamsForTask, cache)
          );

          onDone?.(result);
          closeAsyncMessage?.();

          return result;
        } catch (error) {
          if (isInChain) {
            throw error;
          }

          console.error('EBEmitter - Emit Task - Error', error);

          this.alert(
            this.intl.formatMessage(
              error instanceof EBAPIError && error.errorMessageInResponse
                ? error.errorMessageInResponse
                : '__eb_default_error_message'
            ),
            'error'
          );
        } finally {
          closeAsyncMessage?.();
        }
      }
    );

    this.emitter.on(COMMON_EVENTS.EB_SET_INTERVAL, (params: unknown) => {
      const castParams = params as
        | { key?: string; interval?: number; on?: { interval?: typeof Function } }
        | undefined;
      const { key, interval, on } = castParams ?? {};

      if (!key || !interval || !on?.interval) {
        throw new Error('EBEmitter - eb-set-interval - Missing key, interval or handler');
      }

      this.ebIntervalIds[key] = setInterval(on.interval, interval);

      return Promise.resolve();
    });

    this.emitter.on(COMMON_EVENTS.EB_CLEAR_INTERVAL, (params: unknown) => {
      const castParams = params as { key: string } | undefined;
      const { key } = castParams ?? {};

      if (!key) {
        throw new Error('EBEmitter - eb-clear-interval - Missing key');
      }

      clearInterval(this.ebIntervalIds[key] as number);

      return Promise.resolve();
    });

    this.emitter.on(COMMON_EVENTS.EB_SUBMIT_LOGIN, async (params: unknown) => {
      const closeAsyncMessage = this.showAsyncExecutionMessageInternal();

      try {
        const { success, displayName } = await window.EB.auth.login(params as LoginCredentials);

        closeAsyncMessage();

        return Promise.resolve({
          success,
          displayName,
          policy: (params as LoginCredentials).policy
        });
      } finally {
        closeAsyncMessage();
      }
    });

    this.emitter.on(COMMON_EVENTS.EB_ROUTER_PUSH, async (params: unknown) => {
      const castParams = params as
        | {
            name?: string;
            path?: string;
            params?: Record<string, unknown>;
            query?: Record<string, unknown>;
          }
        | undefined;

      return this.routerPush({
        name: castParams?.name,
        path: castParams?.path,
        params: castParams?.params ?? {},
        query: castParams?.query
      });
    });

    this.emitter.on(COMMON_EVENTS.EB_ROUTER_REPLACE, async (params: unknown) => {
      const castParams = params as
        | {
            name?: string;
            path?: string;
            params?: Record<string, unknown>;
            query?: Record<string, unknown>;
          }
        | undefined;

      return this.routerReplace({
        name: castParams?.name,
        path: castParams?.path,
        params: castParams?.params ?? {},
        query: castParams?.query
      });
    });

    this.emitter.on(COMMON_EVENTS.EB_ROUTE_REDIRECT, async (params: unknown) => {
      console.warn('EBEmitter - eb-route-redirect - Deprecated, use eb-router-push instead');

      const castParams = params as
        | {
            name?: string;
            path?: string;
            params?: Record<string, unknown>;
            query?: Record<string, unknown>;
          }
        | undefined;

      return this.routerPush({
        name: castParams?.name,
        path: castParams?.path,
        params: castParams?.params ?? {},
        query: castParams?.query
      });
    });

    this.emitter.on(COMMON_EVENTS.EB_DOC_TITLE, async (params: unknown) => {
      const castParams = params as
        | {
            title?: string;
          }
        | undefined;

      if (!castParams?.title) {
        throw new Error('EBEmitter - eb-doc-title - Missing title');
      }

      document.title = `${window.EB.intl.formatMessage(castParams.title)} | ${window.EB.appTitle}`;

      return Promise.resolve();
    });

    this.emitter.on(
      COMMON_EVENTS.EB_CACHE_SAVE,
      (params?: { key: object; data: unknown; tags: string[] }) => {
        if (!params) {
          throw new Error('EBEmitter - eb-cache-save - Missing params');
        }

        window.EB.cache.save(params.key, params.data, params.tags);

        return Promise.resolve();
      }
    );

    this.emitter.on(COMMON_EVENTS.EB_CACHE_INVALID, (params?: { tags: string[] }) => {
      if (!params) {
        throw new Error('EBEmitter - eb-cache-invalid - Missing params');
      }

      window.EB.cache.invalidByTag(...params.tags);

      return Promise.resolve();
    });

    this.emitter.on(
      COMMON_EVENTS.EB_STATE_STORE_REMOVE,
      (params?: { key: string[]; match?: (key: string, store: unknown) => boolean }) => {
        if (!params) {
          throw new Error('EBEmitter - eb-state-store-remove - Missing params');
        }

        if (!params.key || !params.key.length) {
          throw new Error('EBEmitter - eb-state-store-remove - Missing key list');
        }

        if (params.match) {
          window.EB.storage.stateStore.removeStoreWhichMatch(params.key, params.match);

          return Promise.resolve();
        }

        window.EB.storage.stateStore.removeStore(...params.key);

        return Promise.resolve();
      }
    );

    this.emitter.on(
      COMMON_EVENTS.EB_POST_MESSAGE,
      (params?: { to: string; type: string; data?: unknown }) => {
        if (!params || !params.type || !params.to) {
          throw new Error('EBEmitter - eb-post-message - `type` `to` are required');
        }

        const { to, type, data } = params;
        let windowInstance: Window = window;

        if (to === 'parent') {
          windowInstance = window.parent;
        } else {
          const iframeElement = document.getElementById(to);
          if (!iframeElement || iframeElement.tagName.toLowerCase() !== 'iframe') {
            throw new Error('EBEmitter - eb-post-message - `to` is not a valid id of an iframe');
          }
          const iframeWindow = (iframeElement as HTMLIFrameElement).contentWindow;

          if (!iframeWindow) {
            throw new Error('EBEmitter - eb-post-message - `iframeWindow` is not available');
          }

          windowInstance = iframeWindow;
        }

        windowInstance.postMessage(
          {
            from: window.EB.appName,
            type,
            data: clone(data)
          },
          window.location.origin
        );

        return Promise.resolve();
      }
    );

    this.emitter.on(COMMON_EVENTS.EB_SIGNAL_SUBSCRIBE, (params: unknown) => {
      const castParams = params as { channel?: string[] } | undefined;
      const { channel } = castParams ?? {};

      if (!channel) {
        throw new Error('EBEmitter - eb-signal-subscribe - Missing channel in params');
      }
      window.EB.signal.subscribe(channel);

      return Promise.resolve();
    });

    this.emitter.on(COMMON_EVENTS.EB_SIGNAL_UNSUBSCRIBE, (params: unknown) => {
      const castParams = params as { channel?: string[] } | undefined;
      const { channel } = castParams ?? {};

      if (!channel) {
        throw new Error('EBEmitter - eb-signal-unsubscribe - Missing channel in params');
      }

      window.EB.signal.unsubscribe(channel);

      return Promise.resolve();
    });

    this.emitter.on(COMMON_EVENTS.EB_AUTHORIZE, (params: unknown) => {
      const action = params as AuthorizeAction;

      return Promise.resolve(window.EB.auth.authorize(action) === AuthorizeResult.Allow);
    });

    window.addEventListener(
      'message',
      (event) => {
        if (typeof event.data !== 'object') {
          return;
        }

        const { data, type, from } = event.data ?? {};

        if (!data && !type && !from) {
          return;
        }

        this.emit(COMMON_EVENTS.EB_MESSAGE_RECEIVED, { orgEvent: event, data, type, from });
      },
      false
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  on(name: string | undefined, handler: (params?: any) => void | Promise<void>): void {
    if (!name) {
      return;
    }

    if (this.logOnOffEvents) {
      console.log(`EBEmitter - On "${name}"`);
    }

    this.emitter.on(name, handler);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  off(name: string | undefined, handler: (params?: any) => void | Promise<void>): void {
    if (!name) {
      return;
    }

    if (this.logOnOffEvents) {
      console.log(`EBEmitter - Off "${name}"`);
    }

    this.emitter.off(name, handler);
  }

  emit(
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    params?: any,
    authorize?: AuthorizeAction,
    handler?: (params?: unknown) => unknown,
    delay?: number
  ): void {
    const authorizedResult = window.EB.auth.authorize(authorize);

    if (this.logEnabled) {
      console.log(`EBEmitter - Emit "${name}" - Authorized "${authorizedResult}"`, { params });
    }

    if (authorizedResult !== AuthorizeResult.Allow) {
      return;
    }

    const executor = () => {
      switch (name) {
        case COMMON_EVENTS.ALERT: {
          alert(params?.message ?? 'EB - Alert');
          break;
        }
        case COMMON_EVENTS.LOG_INFO: {
          console.log(params?.message ?? 'EB - Log', params?.data ?? {});
          break;
        }
        case COMMON_EVENTS.EB_MANUAL: {
          handler && handler(params);
          break;
        }
        default: {
          this.emitter.emit(name, params);
          break;
        }
      }
    };

    if (delay != null) {
      setTimeout(executor, delay);
    } else {
      executor();
    }
  }

  async emitChain(chain: EmitChain[]): Promise<void> {
    if (this.logEnabled) {
      console.log(`EBEmitter - Emit Chain`, { chain });
    }

    let closeAsyncMessage: (() => void) | undefined;
    let withoutSpin = false;
    let withoutMask = false;

    const stepForCatchingError = chain.find((e) => e.name === COMMON_EVENTS.EB_CHAIN_ERROR);
    const stepForDone = chain.find((e) => e.name === COMMON_EVENTS.EB_CHAIN_DONE);

    if (stepForCatchingError != null) {
      chain.splice(chain.indexOf(stepForCatchingError), 1);
    }

    if (stepForDone != null) {
      chain.splice(chain.indexOf(stepForDone), 1);
    }

    chain.forEach((e, index) => {
      if (e.key) {
        return;
      }

      let key = e.name;
      let i = 0;

      while (chain.some((other, otherIndex) => otherIndex !== index && other.key === key)) {
        i++;
        key = `${e.name}-${i}`;
      }

      e.key = key;
    });

    const findChildrenEvents = (array: EmitChain[], dependKey: string): EmitChain[] => {
      return array
        .filter(
          (e) =>
            e.depends?.includes(dependKey) &&
            window.EB.auth.authorize(e.authorize) === AuthorizeResult.Allow
        )
        .map((e) => ({
          ...e,
          children: findChildrenEvents(chain, e.key)
        }));
    };

    const chainWithChildren = chain
      .filter(
        (e) =>
          !e.depends?.length &&
          window.EB.auth.authorize(e.authorize) === AuthorizeResult.Allow &&
          // Do this to support `checks` on first elements of chain
          (!e.checks || e.checks({}).every(Boolean))
      )
      .map((e) => ({ ...e, children: findChildrenEvents(chain, e.key) }));

    const emitResults: Record<string, unknown> = {};
    const emitStatus = chain.reduce<Record<string, boolean>>((prev, current) => {
      return {
        ...prev,
        [current.key]: false
      };
    }, {});

    const resolveEvent = async (e: EmitChain): Promise<unknown> => {
      if (e.depends?.some((dependKey) => !emitStatus[dependKey])) {
        return Promise.resolve();
      }

      const handlers =
        e.name === COMMON_EVENTS.EB_MANUAL && e.handler
          ? [e.handler]
          : ((this.emitter.all.get(e.name) ?? []) as unknown as Array<
              (params: Record<string, unknown>) => unknown
            >);

      const emitParams = { ...emitResults, ...e.params?.(emitResults) };

      emitParams[CUSTOM_PARAMS.IN_CHAIN] = true;
      withoutSpin = !!emitParams[CUSTOM_PARAMS.WITHOUT_SPIN];
      withoutMask = !!emitParams[CUSTOM_PARAMS.WITHOUT_MASK];

      if (this.logEnabled) {
        console.log(`EBEmitter - Emit "${e.name}"`, { params: emitParams });
      }

      if (
        withoutSpin ||
        !(
          [
            COMMON_EVENTS.EB_MUTATION,
            COMMON_EVENTS.EB_QUERY,
            COMMON_EVENTS.EB_MANUAL,
            COMMON_EVENTS.EB_TASK
          ] as string[]
        ).includes(e.name)
      ) {
        closeAsyncMessage?.();
        closeAsyncMessage = undefined;
      } else if (!closeAsyncMessage) {
        closeAsyncMessage = this.showAsyncExecutionMessageInternal(withoutMask);
      }

      if (e.delay) {
        await new Promise((resolve) => setTimeout(resolve, e.delay));
      }

      const results = await Promise.all(
        handlers.map((handler) => Promise.resolve(handler(emitParams)))
      );

      emitResults[e.key] = results[0];
      emitStatus[e.key] = true;

      const nextChildTask = e.children?.filter((childE) => {
        const { checks } = childE;

        return !checks || checks(emitResults).every(Boolean);
      });

      return !nextChildTask ? Promise.resolve() : Promise.all(nextChildTask.map(resolveEvent));
    };

    try {
      await Promise.all(chainWithChildren.map(resolveEvent));
    } catch (error) {
      console.error('EBEmitter - Emit Chain - Error', error, emitResults);

      if (stepForCatchingError) {
        stepForCatchingError?.handler?.(emitResults, error);
        return;
      }

      this.toast(
        this.intl.formatMessage(
          error instanceof EBAPIError && error.errorMessageInResponse
            ? error.errorMessageInResponse
            : '__eb_default_error_message'
        ),
        'error'
      );
    } finally {
      stepForDone?.handler?.(emitResults);
      closeAsyncMessage?.();
    }
  }
}
